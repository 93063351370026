.kheader {
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 6vh;
}

table, tr, td, th {
    margin: auto;
    border: 1px solid;
}

tr:nth-child(odd) {
    background-color: #be8de0;
}

.optionMenu span {
    cursor: pointer;
}

.kcontainer {
    padding-top: 1vh;
    color: #734D8E;
    background-color: #be8de0;
    text-align: center;
    font-size: 1.5em;
    height: 5vh;
    font-family: Montserrat;
    font-weight: 400;
    font-style: normal;
    max-width: 1366px;
    width: 100vw;
    position: relative;
    margin: auto;
    text-align: center;
}

.sd-root-modern--mobile .sd-matrix__responsive-title {
    display: block !important;
}