.App {
  text-align: center;
}

#root, .App {
  height:100%;
  min-height: 100vh;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.sd-root-modern {
  max-width: 1366px;
  margin: auto;
  width: 100vw;
  min-height: 94vh;
  padding-top: 6vh;
  --primary: #be8de0;
}

.sd-item__decorator {
  background-color: #734D8E !important;
  --primary: #be8de0;
  --background: #734D8E;
  --background-dim: #4d4d4d;
  --background-dim-light: #272222;
  --foreground: black;
}

.survey-message {
  padding-top: 40vh;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
