#root.DarkTheme .sd-root-modern {
    --primary: #be8de0;
    --primary-light: rgba(26, 183, 250, 0.1);
    --secondary: #be8de0;
    --secondary-foreground: #5eda79;
    --background: #555555;
    --background-dim: #4d4d4d;
    --background-dim-light: #272222;
    --foreground: #ededed;
  }

#root.DarkTheme .kheader {
  background: #555555;
  color: #be8de0 !important;
  --primary: #be8de0;
  --primary-light: rgba(26, 183, 250, 0.1);
  --secondary: #be8de0;
  --background: #555555;
  --background-dim: #4d4d4d;
  --background-dim-light: #4d4d4d;
  --foreground: #ededed;
}

#root.DarkTheme .survey-message, #root.DarkTheme .optionMenu {
  color: #ededed
}

#root.DarkTheme .App {
  background: #555555;
}

#root.DarkTheme a {
  text-decoration: none;
  color: #be8de0;
}

#root.DarkTheme input, #root.DarkTheme select, #root.DarkTheme button {
  background-color: #fffdf7;
  color: #be8de0;
}
  
#root.DarkTheme .sd-dropdown__value input {
  background: #555555;
}